@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-Regular.ttf) format('truetype');
}

@font-face {
    font-weight: 700;
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-Bold.ttf) format('truetype');
}

@font-face {
    font-weight: 600;
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-SemiBold.ttf) format('truetype');
}

@font-face {
    font-weight: 500;
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-Medium.ttf) format('truetype');
}

@font-face {
    font-weight: 400;
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-Regular.ttf) format('truetype');
}

@font-face {
    font-weight: 300;
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-Light.ttf) format('truetype');
}

@font-face {
    font-weight: 200;
    font-family: 'Oswald';
    src: local('Oswald'), url(../fonts/Oswald/static/Oswald-ExtraLight.ttf) format('truetype');
}


@font-face {
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-Regular.ttf) format('truetype');
}

@font-face {
    font-weight: 700;
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-Bold.ttf) format('truetype');
}

@font-face {
    font-weight: 600;
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-SemiBold.ttf) format('truetype');
}

@font-face {
    font-weight: 500;
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-Medium.ttf) format('truetype');
}

@font-face {
    font-weight: 400;
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-Regular.ttf) format('truetype');
}

@font-face {
    font-weight: 300;
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-Light.ttf) format('truetype');
}

@font-face {
    font-weight: 200;
    font-family: 'Exo';
    src: local('Exo'), url(../fonts/Exo/static/Exo-ExtraLight.ttf) format('truetype');
}